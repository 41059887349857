
import { Vue, Component } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import Breadcrumbs from '@/ui/components/components/Breadcrumbs.vue';

/**
 * Documentation page.
 * Contains from User Manual, API Documentations, Device Connectivity pages
 */
@Component({
  components: {
    Breadcrumbs,
  },
})
export default class Documentation extends Vue {
  @Getter('navigation/documentationNavigationFiltered') documentationNavigationFiltered!: any;

  get currentRoute() {
    return (this.$route.meta as any).documentationView;
  }
}
