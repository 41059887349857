
import { Breadcrumb } from '@/types/common.types';
import { Vue, Component, Watch } from 'vue-property-decorator';
import { Route, RouteRecord } from 'vue-router';

/**
 * Component that represent breadcrumbs
 */
@Component
export default class Breadcrumbs extends Vue {
  breadcrumbs: Breadcrumb[] = []

  @Watch('$route')
  onRouteChange(route: Route) {
    this.initBreadcrumbs(route);
  }

  /**
   * Creates breadcrumb object from route object
   * @param routerObj route
   */
  initBreadcrumbs(routerObj: Route) {
    this.breadcrumbs = routerObj.matched.map((item: RouteRecord) => ({
      text: item.meta.locale,
      disabled: false,
      href: `/#${item.path}`,
    }));
  }

  mounted() {
    this.initBreadcrumbs(this.$route);
  }
}
